import * as React from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Modal } from "antd";
import {
  RootWrapper,
  HeadContainer,
  Body,
  MenuBox,
  MenuTitle,
  Label,
} from "./styled";
import ShortUniqueId from "short-unique-id";

interface IProps {}

const Landing: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [isOpen, setOpenStatus] = React.useState<
    "username" | "sessionId" | undefined
  >();
  const [formInput, setFormInput] = React.useState("");

  const closeModal = React.useCallback(() => {
    setOpenStatus(undefined);
  }, []);

  const handleClickCreateSession = React.useCallback(() => {
    setOpenStatus("username");
  }, []);
  const handleClickJoinSession = React.useCallback(() => {
    setOpenStatus("sessionId");
  }, []);
  const handleOk = React.useCallback(() => {
    if (isOpen === "username") {
      const tmpId = new ShortUniqueId({ length: 10 }).rnd();
      navigate(`/session/${formInput}-${tmpId}`);
    } else {
      navigate(`/session/${formInput}`);
    }

    closeModal();
  }, [closeModal, formInput, isOpen, navigate]);

  const handleCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback((e) => {
      setFormInput(e.currentTarget.value);
    }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        if (e.key === "Enter") {
          handleOk();
        }
      },
      [handleOk],
    );

  return (
    <>
      <RootWrapper>
        <HeadContainer>
          <h1>
            <FormattedMessage id="landing_title" />
          </h1>
        </HeadContainer>
        <Body>
          <MenuBox key="menu_create_session" onClick={handleClickCreateSession}>
            <MenuTitle>
              <FormattedMessage id="create_session" />
            </MenuTitle>
          </MenuBox>
          <MenuBox key="menu_join_session" onClick={handleClickJoinSession}>
            <MenuTitle>
              <FormattedMessage id="join_session" />
            </MenuTitle>
          </MenuBox>
        </Body>
      </RootWrapper>
      <Modal
        title={intl.formatMessage({
          id:
            isOpen === "username"
              ? "modal_title_create_session"
              : "modal_title_join_session",
        })}
        open={Boolean(isOpen)}
        cancelText={intl.formatMessage({
          id: "modal_button_cancel",
        })}
        okText={intl.formatMessage({
          id:
            isOpen === "username"
              ? "modal_button_create_session"
              : "modal_button_join_session",
        })}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Label htmlFor="input_01">
          <FormattedMessage
            id={
              isOpen === "username"
                ? "create_session_form_username"
                : "join_session_input_id"
            }
          />
        </Label>
        <Input
          id="input_01"
          autoComplete="off"
          autoFocus={true}
          showCount={true}
          onChange={handleUsernameChange}
          maxLength={20}
          onKeyDown={handleKeyDown}
        />
      </Modal>
    </>
  );
};

export default Landing;
