import React from "react";
import { createRoot } from "react-dom/client";
import loadable from "@loadable/component";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Landing from "./pages/landing";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyles } from "./styled";

const IntlAsset = loadable.lib(
  () => import(`./assets/${navigator.language.split("-")[0]}.json`),
);

const routerContext = createBrowserRouter([
  {
    path: "/",
    Component: Landing,
  },
  {
    path: "/session/:id",
    loader: () => <div>loading...</div>,
    Component: loadable(() => import("./pages/session")),
  },
]);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <IntlAsset>
      {({ default: messages }: { default: any }) => {
        const tfMSG = messages.reduce(
          (acc: any, msg: any) => ({
            ...acc,
            [msg.term]: msg.definition,
          }),
          {},
        );

        return (
          <IntlProvider
            messages={tfMSG}
            locale={navigator.language}
            defaultLocale="ko"
          >
            <RouterProvider router={routerContext} />
          </IntlProvider>
        );
      }}
    </IntlAsset>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
