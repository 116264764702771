import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
}

:root {
  --background: #fff;
  --text: #363636;
  --heading-text: #000;
  --anchor-text: #0076d1;
  --code-background: #efefef;
  --code-text: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #202b38;
    --text: #dbdbdb;
    --heading-text: #fff;
    --anchor-text: #0076d1;
    --code-background: #161f27;
    --code-text: #ffbe85;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--background);
  color: var(--text);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  background-color: var(--code-background);
  color: var(--code-text);
}

* {
  font-family: "Noto Sans KR", "Noto Sans", sans-serif;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-text);
  font-family: 'Black Han Sans', sans-serif;
}

p, span,  li, ul, ol, button, input, textarea {
  color: var(--text);
}

a {
  color: var(--anchor-text);
}

button,
[role="button"] {
  cursor: pointer;
}

`;
