import styled from "styled-components";

export const RootWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;

    background-image: url("./resource/landing_bg.webp");
    filter: blur(5px) grayscale(1);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  h1 {
    font-size: 56px;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const Body = styled.div`
  display: grid;
  column-gap: 12px;
  row-gap: 16px;

  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MenuTitle = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: #aaaaaa;

  @media (prefers-color-scheme: dark) {
    color: #333333;
  }
`;

export const MenuBox = styled.button`
  width: 100%;
  min-height: 120px;
  height: 100%;
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #050505;
  border: none;

  @media (prefers-color-scheme: dark) {
    background-color: #f5f5f5;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
`;
